// HealthPage.js

import React from 'react';

function HealthPage() {
    return (
        <div>
            <h1>Welcome to the Health Page!!!</h1>
            <h3>React server is alive and routing is Fine !!!</h3>
        </div>
    );
}

export default HealthPage;
